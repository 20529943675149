// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submitButton"];

  enableButton() {
    this.submitButtonTarget.removeAttribute("disabled"); // Re-enable the button
  }

  initialize() {}

  connect() {
    console.log("connected");
  }

  validateEmpCounts(e) {
    if (
      !this.validState("ID") ||
      !this.validState("OR") ||
      !this.validState("WA")
    ) {
      alert(
        "Local contest total must equal State Employee Count, please confirm totals and click save again."
      );
      e.preventDefault();
    }
    setTimeout(() => {
      this.enableButton();
    }, 200);
  }

  validState(stateId) {
    let state;
    switch (stateId) {
      case "OR":
        state = "oregon";
        break;
      case "WA":
        state = "washington";
        break;
      default:
        state = "idaho";
    }
    let ttl = 0;
    document
      .querySelectorAll(`.local_contest_count_${stateId}`)
      .forEach((v) => {
        ttl += Number(v.value);
      });
    const state_ttl = document.getElementById(
      `preregistration_${state}_emp_count`
    ).value;
    if (state_ttl / ttl > 1.15 || state_ttl / ttl < 0.9) return false;
    return true;
  }
}
